<!--
 * @Author: HZH
 * @Date: 2021-09-16 14:16:01
 * @LastEditors: HZH
 * @LastEditTime: 2021-09-18 09:37:26
 * @Description: 
-->
<template>
	<div>
		<div class="home">
			<Head></Head>
			<div class="content">
				<div class="wrapper">
					<!-- <div class="swipe">
						<el-carousel height="350px">
							<el-carousel-item v-for="item in swipe" :key="item.id">
								<img style="width: 100%;" :src="item.url" alt="">
							</el-carousel-item>
						</el-carousel>
					</div> -->
					<div class="product_title">关于我们</div>
					<div class="plates">
						<div class="pdd-content">
							<div class="content">
								<div class="sub-title"></div>
								<p>中爱一生网络科技（成都）有限公司成立于2023年01月10日，注册地位于成都市温江区海科路西段101号1栋3楼305室，法定代表人为伍健。经营范围包括一般项目：网络技术服务；互联网销售（除销售需要许可的商品）；第一类医疗器械销售；化妆品批发；化妆品零售；个人卫生用品销售；卫生用品和一次性使用医疗用品销售；消毒剂销售（不含危险化学品）；软件开发；信息系统集成服务；网络与信息安全软件开发；食品互联网销售（仅销售预包装食品）；保健食品（预包装）销售；智能控制系统集成。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：食品互联网销售；医疗器械互联网信息服务；消毒器械销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）中爱一生网络科技（成都）有限公司对外投资1家公司</p>
								
							</div>
						</div>
					</div>
					<div class="footer">
						<Foot></Foot>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import {
		get
	} from "../utils/request";
	import Head from './components/Head.vue'
	import Foot from './components/Foot.vue'
	export default {
		// 使用注册组件
		components: {
			// 1.引入所需组件

			// 2.注册组件
			Head,
			Foot
		},
		data() {
			return {
				// message: "hello",
				swipe: [{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202307101021007d3305321.png",
					},
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202307101021007d3305321.png",
					}
				],

			};
		},
		methods: {},
		created() {},
	};
</script>

<style lang="scss" scoped>
	.content {
		.wrapper {

			// width: 100%;
			.plates {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.plate {
					display: flex;
					width: 25%;
					padding: 10px;
					box-sizing: border-box;
					position: relative;
					margin-bottom: 30px;

					.plate_img {
						display: block;
						width: 100%;
						height: 100%;
						border-top-left-radius: 20px;
						border-top-right-radius: 20px;
					}

					.plate_title {
						margin-top: 15px;
					}
				}
			}
		}

		.product_title {
			font-size: 30px;
			font-weight: 600;
			margin: 20px;
		}
	}

	.footer {
		height: 200px;
		// background-color: #528c38;
	}
</style>